
    export default {
        components: {
            CareerOneHome: () => import(/* webpackChunkName: "CareerOneHome" */ '@/components/Landings/CareerOne/CareerOneHome'),
            GlowUpCareersHome: () => import(/* webpackChunkName: "GlowUpCareersHome" */ '@/components/Landings/GlowUpCareers/GlowUpCareersHome'),
            SearchResultPage: () => import(/* webpackChunkName: "SearchResultPage" */ '@/pages/search-results/index.vue'),
        },

        layout(context) {
            const homeLayoutSiteCodes = [
                'careerone',
                'glowup',
            ];
            return homeLayoutSiteCodes.includes(context.$siteCode) ? 'home' : 'discovery';
        },

        async asyncData({ app, store, redirect, route }) {
            if (route.name === 'home') {
                const me = store.state.me.user;
                if (me.id) {
                    redirect(302, '/discover');
                } else if (app.$isApp) {
                    redirect(302, '/search');
                }
            }
            await store.dispatch('search/getSearchPageMetaData', route.path).then((response) => {
                store.commit('search/SET', { key: 'pageMetaData', value: response.data });
            });

            await store.dispatch('brand/getBrandEeoTags').then((response) => {
                store.commit('search/SET', { key: 'availableEeoTags', value: response.data });
            });
        },
    };
